@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

sup {
  top: -0.1em;
  font-size: 100%;
}

[class*='MuiInputBase-input-MuiOutlinedInput-input'] {
  padding: 14px 14px !important;
}

.react-datepicker-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  font-size: 1rem;
  padding: 3px;
}

.react-datepicker-wrapper::after {
  position: absolute;
  content: '';
  background-image: url('../src/images/calender.svg');
  background-repeat: no-repeat;
  background-position: right;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  pointer-events: all;
}

.react-datepicker-popper {
  z-index: 999;
}

.PhoneInput input {
  outline-width: 0px;
}
